<template>

  <b-overlay :show="loading" :opacity="0.6">
    
    <div class="list-group-wrapper">
        <!--<transition name="fade">
            <div class="loading" v-show="loading">
                <span class="fa fa-spinner fa-spin"></span> Loading
            </div>
        </transition>-->

        <ul id="infinite-list" class="pl-0" v-bind:class="{ 'list-group': isMobile }" ref="content">
            <li class="list-group-item" v-for="item in items.list" :key="item.id">
                <slot name="details" v-bind:data="item"></slot>
            </li>
        </ul>

    </div>

    <b-row class="d-flex align-items-center mt-2">
        <b-col>
          <slot name="buttons"></slot>                    
        </b-col>
        <b-col cols="auto" end v-if="items.total > filters.per_page && !isMobile">                        
            <b-pagination first-number last-number pills align="right" v-model="cFilters.page" :total-rows="items.total" :per-page="cFilters.per_page" size="sm"/>
        </b-col>
        <b-col v-if="items.total > 5 && !isMobile" cols="auto" class="p-0 mr-3 app-local-perpage-min-width">
              <v-select v-model="cFilters.per_page" :clearable="false" :selectOnTab="true" :options="[5, 10, 20, 50, 100]" @input="perPageChanged"/>               
        </b-col>
    </b-row>

    <template #overlay>
        <div></div>
    </template>

  </b-overlay>
</template>

<script>
import vSelect from "vue-select";

export default {
    name: 'AppInfiniteScroll',
    components: {
      vSelect
    },

    props: {

        id: { type: String },
        loading: { type: Boolean, default: false},
        isMobile: {type: Boolean, default: false},
        items: {},
        filters: {},
        scrollInto: {type: Boolean}
    },

    mounted() {
        this.onCreateThis();
    },

    watch: {
        scrollInto(){
          if (this.$refs.content && this.isMobile) {
              this.$refs.content.scrollTop = (this.$refs.content.firstChild.clientHeight * this.filters.per_page * (this.filters.page - 1));
          }
        },


    },

    computed: {
        cFilters: {
            get() { return this.filters },
            set(value) { this.$emit('update:filters', value) }
        },
    },

    data() {
        return {
            
        }
    },


  methods: {
    onCreateThis(){

        const listElm = document.querySelector('#infinite-list');
        listElm.addEventListener('scroll', e => {
            
            if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight && Object.keys(this.items.list).length < this.items.total && this.isMobile) {
                this.onLoadMore();
            }
        });

    },
     
    onLoadMore() {
      this.cFilters.page++;
    }, 

    perPageChanged() {
        this.cFilters.changed++;
    }
  },
}
</script>
<style scoped>
.list-group-wrapper {
  position: relative;
}

.list-group {
  overflow: auto;
  max-height: 30vh;
  min-height: 10vh;
  
}

.list-group-item {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #dce4ec;
}

.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: purple;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.app-local-perpage-min-width {
    min-width: 80px;
}
</style>