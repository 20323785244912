<template>
    <div>
        
        <b-card no-body class="app-card-profile mt-3">
            <app-infinite-scroll :items.sync="items" v-on:onLoadMore="getList(0)" :filters="filters" :loading="loading_list" :isMobile.sync="isMobile" :scrollInto="scrollItem">
                <template v-slot:details="item">
                    <b-row :id="'item-'+item.data.id_laravel_data_error" >
                        <b-col>
                            <h4 class="mb-1">{{item.data.id_laravel_data_error}} {{item.data.source_name}}</h4>
                            <p class="mb-0 app-locat-text">{{item.data.route}}</p>
                        </b-col>

                        <b-col end class="text-right">
                            <app-button>Click me</app-button>
                        </b-col>
                    </b-row>
                </template>
            </app-infinite-scroll>
        </b-card>

    </div>
</template>
<script>
import axios from "@axios";
import AppInfiniteScroll from '@core/components/AppInfiniteScroll.vue';
export default {
    
    components: {
        AppInfiniteScroll
    },

    props: {

    },

    mounted() {
        this.onCreateThis();
    },

    unmounted() {
        window.removeEventListener('resize', this.getDimensions);
    },

    created() {
    },

    data()  {
        return{
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0,
            },
            
            items: {
                list: [],
                refresh: 0,
                total: 0
            },

            isMobile: false,
            width: document.documentElement.clientWidth,
            scrollItem: false
            
        }
    },

    methods: {

        onCreateThis() {
            this.getList(0);

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList(0);
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList(0);
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList(0);
                }   
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList(0);
                } 
            });

            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                this.isMobile = true;
            }else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                this.isMobile = true;
            }else{
                this.isMobile = false;
            }

            window.addEventListener('resize', this.getDimensions);

        },

        getDimensions() {
            
            this.width = document.documentElement.clientWidth;

            if(document.documentElement.clientWidth < 768 && !this.isMobile){
                this.isMobile = true;
                this.getList(1);
            }else if(document.documentElement.clientWidth >= 768 && this.isMobile){
                this.isMobile = false;
                this.getList(0);
            }

        },

        getList(resize){
           this.loading_list = true;
            
           axios
                .post("settings/errors/getList", {
                    filters: JSON.stringify(this.filters),
                    resize: resize
                })
                .then((res) => {                  
                    
                    this.filters.page = res.data.page;

                    if(this.isMobile && resize == 0){
                        res.data.items.list.forEach(item => {
                            this.items.list.push(item);    
                        });
                        
                    }else{
                        this.items.list = res.data.items.list;
                    }

                    this.items.refresh = res.data.items.refresh;
                    this.items.total = res.data.items.total;

                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    
                    if(this.isMobile && resize == 1){
                        this.scrollItem = !this.scrollItem;
                    }
                    
                    
                    this.loading_list = false;
                });
        },

        
    },
    
}
</script>
<style>
.app-local-max-width{
    max-width: 20px;
}

.app-locat-text{
    font-size: 11px;
}
</style>